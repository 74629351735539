<template>
  <!-- 弹窗搜索+分页+表操作，不需要搜索(isSearch)、分页(isPage)、prop直接传false即可,header则是自定义内容,比如表上存在表单 -->
  <el-dialog :title="title"
             :close-on-click-modal="false"
             :visible.sync="show"
             :width="width"
             :before-close="()=>$emit('close')">
    <slot name="header"></slot>
    <div style="display: flex;align-items: center;justify-content: space-between;">
      <div v-if="isSearch">
      <GlobalForm
          :form-item-list="formItemList"
          :is-reset-form="isResetForm"
          round
          size="mini"
          :inline="true"
          @handleConfirm="(v)=>$emit('handleConfirm',v)"
          confirm-btn-name="查询"
      >
      </GlobalForm>
    </div>
    <div v-if="isAction" style="margin-bottom: 15px">
      <slot name="action"></slot>
    </div>
    </div>
    <!-- 存在分页，高度自适应，不存在分液，再次判断当前数量数量是否大于15,大于15，显示滚动条，小于10不设置固定高度，大于10，设置固定高度 -->
    <!-- element内部的高度没用监听，导致更新了状态，但dom不更新，因此单独拿一个盒子设置dom -->
    <div :style="{height:height,overflow:'auto'}">
      <el-table
          border
          ref="tableEle"
          size="small"
          :header-cell-style="{background:'#E4ECFD',padding:0}"
          v-loading="loading"
          :data="tabData"
          @select="(v,row)=>$emit('handleSelect',v,row)"
          @select-all="(v)=>$emit('handleSelectAll',v)"
          @selection-change="(v)=>$emit('handleSelectionChange',v)"
      >
        <el-table-column
            v-if="isTabSelection"
            type="selection"
            align="center"
            :selectable="(v)=>selectable(v)"
            width="55">
        </el-table-column>
        <el-table-column v-for="item in tableColumns"
                         :key="item.prop"
                         :prop="item.prop"
                         align="center"
                         :label="item.label"
                         :type="item.type"
                         :width="item.width"
        >
          <template v-slot="{row}">
            <div v-if="isSlot">
              <!-- 通过插槽将子组件的值传给父组件 -->
              <slot :row="row" :prop="item.prop"></slot>
            </div>
            <div v-else>
              <slot>{{ row[item.prop] }}</slot>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div v-if="isPage" style="overflow:hidden;">
      <Pagination :meta="meta"
                  :marginNum="10"
                  @sizeChange="(v)=>$emit('sizeChange',{meta:{...meta,pageSize: v},params:{...params,pageSize:v}})"
                  @pageChange="(v)=>$emit('pageChange',{meta:{...meta,pageNum: v},params:{...params,pageNum:v}})"
      />
    </div>
    <div slot="footer" class="dialog-footer" v-if="isBtn">
      <el-button @click="$emit('close')">{{ cancelText }}</el-button>
      <el-button type="primary" @click="$emit('config')">{{ configText }}</el-button>
    </div>
    <!-- 如果沒有操作按钮时，添加一个底部高度 -->
    <div v-if="!isBtn" style="padding-bottom: 30px"></div>
  </el-dialog>
</template>
<script>
import GlobalForm from "@/components/global/components/GlobalForm.vue";
import Pagination from "@/components/common/Pagination.vue";
import {pageSize} from "@/utils/common";

export default {
  components: {Pagination, GlobalForm},
  watch: {
    // 不用监听无法设置，
    tabData(v) {
      if (this.isPage) {
        this.newEchoData(v);
        return null;
      }
      // 是否分页，如果分页，在异步rq过程中，会多次触发选项事件，因此多选复显单独操作
      // 存在选择方式（多选）控制回显
      if (this.isTabSelection) {
        this.echoData();
      }
      // 未分页动态计算高度
      if (v.length > 15) {
        return this.height = '60vh'
      }
      // 未分页动态计算高度
      if (v.length > 10) {
        return this.height = '40vh'
      }
      // 未分页动态计算高度
      if (v.length <= 10) {
        return this.height = "auto";
      }
    },
  },
  data() {
    return {
      // 表的高度
      height: "auto"
    }
  },
  methods:{
    // 禁用选择
    // selectable(row){
    //   console.log(this.isSelectDistance)
    //   // 如果不需要禁用，选择false
    //   if(!this.isSelectDistance){
    //     return true;
    //   }
    //   // 如果需要禁用，通过传的字段进行判断，默认isOpenNiceBiz
    //   return this.$emit("selectable",row);
    // },
    // 多选数据项回显
    echoData(){
      this.allSelectData.forEach(v => {
        // 解决dom更新异步问题
        this.$nextTick(() => {
          this.$refs.tableEle.toggleRowSelection(v);
        })
      })
    },
    // 由于指针问题，需要通过固定的key去重匹配字段，主键值外部传入
    newEchoData(v){
      if(v.length && this.isTabSelection){
        this.allSelectData.forEach(item=>{
          // listKey外部传入
          const current = v.find(v=>v[this.listKey] === item[this.listKey]);
          // 解决dom更新异步问题
          this.$nextTick(() => {
            this.$refs.tableEle.toggleRowSelection(current);
          })
        })
      }
    },
  },
  props: {
    // 是否重置表单
    isResetForm: {
      type: Boolean,
      default: false
    },
    // 弹窗确定按钮文字
    configText: {
      type: String,
      default: "确 定"
    },
    cancelText: {
      type: String,
      default: "取 消"
    },
    // 弹窗标题
    title: {
      type: String,
      default: ""
    },
    // 弹窗显示
    show: {
      type: Boolean,
      default: false
    },
    // 弹窗宽度
    width: {
      type: String,
      default: "30%"
    },
    // 搜索参数
    formItemList: {
      type: Array,
      default: () => []
    },
    // 表格数据
    tabData: {
      type: Array,
      default: () => []
    },
    // 加载
    loading: {
      type: Boolean,
      default: true
    },
    // 表列
    tableColumns: {
      type: Array,
      default: () => []
    },
    // 是否使用插槽
    isSlot: {
      type: Boolean,
      default: () => true
    },
    // 是否表格多选
    isTabSelection: {
      type: Boolean,
      default: () => false
    },
    // 当前选择项
    allSelectData: {
      type: Array,
      dafault: () => []
    },
    // 是否分页
    isPage: {
      type: Boolean,
      default: true
    },
    // 是否搜索
    isSearch: {
      type: Boolean,
      default: true
    },
    // 表和搜索之间的额外操作
    isAction:{
      type:Boolean,
      default:false
    },
    // 是否显示确定和取消按钮
    isBtn: {
      type: Boolean,
      default: true
    },
    // 分页数据
    meta: {
      type: Object,
      default: () => {
        return {
          total: 0,
          pageSize,
          pageNum: 1,
        }
      }
    },
    // 列表搜索参数
    params: {
      type: Object,
      default: () => {
        return {
          pageSize,
          pageNum: 1
        }
      }
    },
    // 主键key，当需要使用多选时传入
    listKey:{
      type:String,
      default:""
    },
    // // 多选选择项是否禁用
    // isSelectDistance:{
    //   type:Boolean,
    //   default:false
    // },
    selectable:{
      type:Function,
      default:(v)=>true
    }
    // 禁用选择框字段
    // selectField:{
    //   type:String,
    //   default:"isOpenNiceBiz"
    // }
  },
  // handleConfirm:搜索触发，v代表搜索的值
  // close弹窗关闭
  // config:弹窗确定事件
  // sizeChange：分页数量变化
  // pageChange：分页页数变化
  // handleSelectionChange 选择项变化
  // handleSelect 单行点击
  // handleSelectAll 所有项
  emits: ['handleConfirm', 'close', 'config', 'sizeChange', 'pageChange', 'handleSelectionChange','handleSelect','handleSelectAll']
}
</script>
